import React from "react"
import { graphql } from "gatsby"
import { Card, Image } from "react-bootstrap";
import "./main.scss";
import Layout from "../components/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faJava, faAngular, faHtml5, faCss3, faSass, faAws, faReact, faPython, faJs, faNodeJs } from "@fortawesome/free-brands-svg-icons";
import { faBrain, faCalculator, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Helmet from "react-helmet";
library.add(fab);
export const pageQuery = graphql`
query {
site {
siteMetadata {
title
}
}
allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
edges {
node {
    excerpt
    fields {
    slug
    }
    frontmatter {
    date(formatString: "MMMM DD, YYYY")
    title
    description
    }
}
}
}
}
`

interface AboutProps extends Partial<GatsbyProps> {
    data: {
        site: {
            siteMetadata: {
                name: string,
                tagline: string
            }
        },
    }
}


export default class About extends React.Component<AboutProps, {}> {
    render = () => {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.name;

        return (
            <Layout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Joey Albano - About</title>
                    <link rel="canonical" href="https://joeyalbano.com/about" />
                    <meta name="description" content="Career and educational details for Joey Albano, software engineer." />
                </Helmet>
                <div>
                    <h1>Career Timeline</h1>
                    <p><a href="/assets/resume.pdf">&nbsp;Resume/CV Download</a></p>
                    <div>
                        <Card className="mb-2">
                            <Card.Body className="m-0">
                                <Card.Title className="mb-2">Software Development Engineer</Card.Title>
                                <Card.Subtitle>Amazon</Card.Subtitle>
                                <small className="text-muted ">Cupertino, CA</small>
                                <Card.Text>
                                    INTech North America Retail Expansion (NARX) team!
                                    <br />
                                    We work on solutions to meet the needs of Amazon customers in Canada and Mexico.
                                </Card.Text>
                                <Card.Text className="break-text tech-used-icons">
                                    Technologies used:
                                    <br />
                                    <FontAwesomeIcon icon={faJava} className="h4" title="Java"></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faAws} className="h4" title="AWS"></FontAwesomeIcon>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Jan 2020 - Present</small>
                            </Card.Footer>
                        </Card>
                        <Card className="mb-2">
                            <Card.Body className="m-0">
                                <Card.Title className="mb-2">Fullstack Engineer</Card.Title>
                                <Card.Subtitle>Intellective</Card.Subtitle>
                                <small className="text-muted ">Mountain View, CA</small>
                                <Card.Text>
                                    I built ServiceNow platform API integrations with mobile and desktop clients. Most of my work involved
                                    building a cross-platform mobile app with Angular and TypeScript. I built an application for Windows and MacOS
                                    using ElectronJS + React that was able to interface with ServiceNow, too.
                                </Card.Text>
                                <Card.Text className="break-text tech-used-icons">
                                    Technologies used:
                                    <br />
                                    <FontAwesomeIcon icon={faAngular} className="h4" title="Angular2"></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faHtml5} className="h4" title="HTMl5"></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faCss3} className="h4" title="CSS3"></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faSass} className="h4" title="SASS"></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faJs} className="h4" title="js"></FontAwesomeIcon>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Dec 2018 - Dec 2019</small>
                            </Card.Footer>
                        </Card>
                        <Card className="mb-2">
                            <Card.Body className="m-0">
                                <Card.Title className="mb-2">Data Analyst/Mathematics Instructor</Card.Title>
                                <Card.Subtitle>Afficient Academy</Card.Subtitle>
                                <small className="text-muted ">Cupertino, CA</small>
                                <Card.Text>
                                    Analytical work and automation. I developed scripts to predict student churn using Python + Keras ("machine learning") and automated
                                    manual processes for charting student progress and client outreach. Most of this work boiled down to creating dashboards using React
                                    with data sourced from MongoDB through API calls and/or scheduled batch read jobs.
                                </Card.Text>
                                <Card.Text className="break-text tech-used-icons">
                                    Technologies used:
                                    <br />
                                    <FontAwesomeIcon icon={faReact} className="h4" title="React"></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faPython} className="h4" title="Python"></FontAwesomeIcon>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Sept 2017 - Nov 2018</small>
                            </Card.Footer>
                        </Card>
                        <Card className="mb-2">
                            <Card.Body className="m-0">
                                <Card.Title className="mb-0">Education</Card.Title>
                                <small className="text-muted ">California</small>
                                <Card.Text>
                                    <p>I graduated high school in the Bay Area and studied Mathematics/Economics at the University of California,
                                        San Diego for a BS. Afterward, I enrolled in Santa Clara University's JD program but dropped out within a month.</p>
                                    <p>Following the dropout I spent around a year and a half as a self-employed private tutor. I also took up work as a contractor
                                        for AJ Tutoring and Manhattan for general academic tutoring and SAT + LSAT test prep. </p>
                                    <p>When not at work,
                                        I studied Computer Science. I took introductory CS classes at Foothill and De Anza College, supplementing my learning with free
                                        online resources and personal programming projects. My first completed <a href="https://github.com/jalbatross/Netty-Chat-Server">project</a> was a poorly implemented but functional chat app that allowed users to also
                                        play Rock Paper Scissors in real time. I built a browser-based web client for the app using AngularJS along with a CLI written in Java. I used Netty
                                        for my server and dipped my fingers into databases with a PostgreSQL DB for user credentials. The entire stack was hosted on a single EC2 instance.</p>
                                </Card.Text>
                                <Card.Text className="break-text tech-used-icons">
                                    Technologies used:
                                    <br />
                                    <FontAwesomeIcon icon={faAngular} className="h4" title="angular"></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faJava} className="h4" title="java"></FontAwesomeIcon>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Until Sept 2017</small>
                            </Card.Footer>
                        </Card>
                        <Card className="mb-2">
                            <Card.Body className="m-0">
                                <Card.Title className="mb-0">Misc</Card.Title>
                                <small className="text-muted ">Other Stuff</small>
                                <Card.Text>
                                    <p>I have a variety of hobbies:</p>
                                    <ul>
                                        <li>Language Learning (the current challenge is 廣東話)</li>
                                        <li>Gaming</li>
                                        <li>Weightlifting</li>
                                        <li>Music: Guitar and Piano</li>
                                        <li>Magic: the Gathering</li>
                                        <li>Dungeons and Dragons</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted"></small>
                            </Card.Footer>
                        </Card>
                    </div>
                </div>
            </Layout >
        )
    }
}



